import _ from 'lodash';
import React, { useState } from 'react'
import MessageRender from '../../Message/MessageRender';
import PlaylistPreview from '../../../playlist/components/PlayListTable/PlaylistPreview';

interface InputProps {
    playlist: any,
    inputSelect?: any,
    handelChangePlaylist: (playlist: any, e: any) => void,
    selectedIcon: any,
    selectedList?: any,

}

const AddPlayListRow: React.FC<InputProps> = ({ playlist, handelChangePlaylist, inputSelect, selectedIcon, selectedList }) => {

    return (
        <tr key={playlist.Id}>
            <td>
                <div className='px-4'>
                    <input onChange={(e) => handelChangePlaylist(playlist, e)} name={inputSelect == 'single' ? 'radio' : ''}
                        className="form-check-input small" type={inputSelect == 'single' ? 'radio' : 'checkbox'}
                        value={playlist.Id}
                        id={`check-${playlist.Id}`}
                        checked={inputSelect === 'single' ? selectedList && selectedList.Id === playlist.Id : undefined}
                    />
                </div>
            </td>
            <td>{playlist.Title}</td>
            <td>{playlist.Description}</td>
            <td>
                {((playlist.FrontPreviewUrl || playlist.RearPreviewUrl) && selectedIcon != 'Image') ?
                    <div className='d-flex gap-5'>
                        {playlist.FrontPreviewUrl && playlist.ShowAll &&
                            <PlaylistPreview selectedIcon={selectedIcon} previewUrl={playlist.FrontPreviewUrl} pngPreviewUrl={playlist.ShowAll[0].PreviewUrl} />
                        }
                        {playlist.FrontPreviewUrl && playlist.ShowFront &&
                            <PlaylistPreview selectedIcon={selectedIcon} previewUrl={playlist.FrontPreviewUrl} pngPreviewUrl={playlist.ShowFront[0].PreviewUrl} />
                        }
                        {playlist.RearPreviewUrl && playlist.ShowRear &&
                            <PlaylistPreview selectedIcon={selectedIcon} previewUrl={playlist.RearPreviewUrl} pngPreviewUrl={playlist.ShowRear[0].PreviewUrl} />
                        }
                    </div>
                    :
                    <div className='d-flex flex-row gap-5 flex-wrap'>
                        {playlist.ShowAll && playlist.ShowAll.map((msg: any, i: number) => {
                            return (
                                <React.Fragment key={`img-${playlist.Id}-${i}`} >
                                    <MessageRender message={msg}></MessageRender>
                                </React.Fragment>
                            )
                        })}
                        {playlist.ShowFront && playlist.ShowFront.map((msg: any, i: number) => {
                            return (
                                <React.Fragment key={`img-${playlist.Id}-${i}`} >
                                    <MessageRender message={msg}></MessageRender>
                                </React.Fragment>
                            )
                        })}
                        {playlist.ShowRear && playlist.ShowRear.map((msg: any, i: number) => {
                            return (
                                <React.Fragment key={`img-${playlist.Id}-${i}`} >
                                    <MessageRender message={msg}></MessageRender>
                                </React.Fragment>
                            )
                        })}
                    </div>
                }
            </td>
        </tr>
    )
}

export default AddPlayListRow;
