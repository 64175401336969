import React, { useState } from 'react';
import './PlaylistView.scss';
import { useLocation } from 'react-router-dom';
import { getMessagesWithJWT, getPlayListWithJWT, getUserWithJWT } from '../../redux/api';
import Message from './Message';
import { KTSVG } from '../../../_metronic/helpers';

const PlaylistView = () => {
    const location = useLocation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [playList, setPlayList] = useState<any>([])
    // const [messages, setMessages] = useState<any>([])
    const [token, setToken] = useState<any>(null);
    const [id, setId] = useState<any>(null);
    const [user, setUser] = useState<any>(null);

    const getTokenAndIdFromUrl = () => {
        const url: any = new URLSearchParams(location.search).toString();
        const keyValuePairs = url.split('&');
        let token: any = "";
        let id: any = "";
        keyValuePairs.forEach((pair: any) => {
            const [key, value] = pair.split('=');
            if (key === "token") {
                token = value;
            } else if (key === "id") {
                id = value;
            }
        });
        return { token, id };
    };

    React.useEffect(() => {
        const { token, id } = getTokenAndIdFromUrl();
        // setToken(token);
        // setId(id);
        getUser(token, id);
    }, [])

    const getUser = (jwtToken: any, playlistId?: any) => {
        setIsLoading(true);
        getUserWithJWT(jwtToken).then((res: any) => {
            if (res && res.Response) {
                const usr = res.Response[0]
                setUser(res.Response[0]);
                getPlayList(jwtToken, usr.CustomerAccess[0], playlistId)
            }
            setIsLoading(false);
        }).catch((e) => {
            setIsLoading(false)
        })
    }

    const extractMessages = (arr: any) => {
        // return arr.flatMap((obj: any) => {
        let arrayMessages: any = [];
        // arr.forEach((obj: any) => {
        if (arr.hasOwnProperty("ShowAll")) {
            arrayMessages.push(...Object.values(arr.ShowAll));
        }
        if (arr.hasOwnProperty("ShowFront")) {
            arrayMessages.push(...Object.values(arr.ShowFront));
        }
        if (arr.hasOwnProperty("ShowRear")) {
            arrayMessages.push(...Object.values(arr.ShowRear));
        }
        // });
        return arrayMessages;
        // });
    };

    const extractMessagesIds = (data: any) => {
        return data.map((item: any) => item.MessageId);
    };

    const mergeArrays = (firstArray: any, secondArray: any) => {
        return firstArray.map((item: any) => {
            const match = secondArray.find((e: any) => e.Id === item.MessageId);
            if (match) {
                item['StandardOutputStates'] = match['StandardOutputStates'];
                item['DynamicInputs'] = match['DynamicInputs'];
                return { ...item };
            }
            return item;
        });
    };

    const getPlayList = (jwtToken: any, customerId: any, playlistId?: any, deviceGroupId?: any
    ) => {
        let reqData: any = {
            playlist_ids: playlistId
        }

        setIsLoading(true);

        getPlayListWithJWT(jwtToken, customerId, reqData)
            .then((res: any) => {
                if (res && res.Response) {
                    let plList = res.Response.Results[0]
                    // setPlayList(plList)
                    let allMessages = extractMessages(plList);
                    getMessages(jwtToken, customerId, allMessages, plList);
                }
                setIsLoading(false)
            })
            .catch((e) => {
                setIsLoading(false)
            })
    }

    const getMessages = (jwtToken: any, customerId: any, msgs: any, playlist: any
    ) => {
        let ids = extractMessagesIds(msgs);

        let reqData: any = {
            id: ids
        }

        setIsLoading(true);

        getMessagesWithJWT(jwtToken, customerId, reqData)
            .then((res: any) => {
                if (res && res.Response) {
                    let allMessages = res.Response.Results
                    if (playlist.hasOwnProperty("ShowAll")) {
                        let mergedArray = mergeArrays(playlist.ShowAll, allMessages);
                        playlist.ShowAll = mergedArray;
                    }
                    if (playlist.hasOwnProperty("ShowFront")) {
                        let mergedArray = mergeArrays(playlist.ShowFront, allMessages);
                        playlist.ShowFront = mergedArray;
                    }
                    if (playlist.hasOwnProperty("ShowRear")) {
                        let mergedArray = mergeArrays(playlist.ShowRear, allMessages);
                        playlist.ShowRear = mergedArray;
                    }
                    setPlayList(playlist)
                    // let mergedArray = mergeArrays(msgs, allMessages);
                    // setMessages(mergedArray)
                }
                setIsLoading(false)
            })
            .catch((e) => {
                setIsLoading(false)
            })
    }

    // function calculateTotalDuration(messages: any) {
    //     return messages.reduce((sum: any, message: any) => {
    //         return sum + (message.Duration / 1000 || 0);
    //     }, 0);
    // }

    return (
        <div className="playlist-view">


            {/* <div className="header bg-primary">
                <button className="back-button" onClick={(e) => { window.history.back() }}>
                    <i className="fa fa-arrow-left fs-3 mr-10 text-white" aria-hidden="true"></i>
                    BACK</button>
                <img className="logo" src={user?.AvatarURL} alt="Logo" />
            </div> */}
            <div className="playlist-content">
                <div className='previous'>
                    <div className='previous-container' onClick={(e) => { window.history.back() }}>
                        <div className='icon'>
                            <KTSVG
                                path='/media/svg/general/previous.svg'
                                className='previous-icon'
                            />
                        </div>
                        <div className='title'>{localStorage.getItem('pTitle')}</div>
                    </div>
                </div>

                <div className='playlist-heading'>
                    <div className='heading-container'>
                        <div className='heading'>
                            <span className='playlist'>
                                Playlist:&nbsp;
                            </span>
                            <span className='playlist-title'>
                                {playList?.Title}
                            </span>
                        </div>
                    </div>
                    <div className='playlist-description'>Showing all playlists, multilists and app control buttons. Tap to view content.</div>
                    <div className='divider'></div>
                </div>

                {playList.ShowAll && playList.ShowAll.length > 0 &&
                    <>
                        <div className="playlist-list">
                            <div className='playlist-heading'>
                                <div className='heading'> {playList.ShowAll && playList.ShowAll.length} Messages: </div>
                            </div>

                            <div className='playlist-items-container'>
                                {playList.ShowAll.map((message: any, index: any) => (
                                    <React.Fragment key={index}>
                                        <React.Fragment key={index}>
                                            <Message message={message} />
                                        </React.Fragment>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </>
                }
                {playList.ShowFront && playList.ShowFront.length > 0 &&
                    <>
                        <div className="playlist-list">
                            <div className='playlist-heading'>
                                <div className='heading'> {playList.ShowFront && playList.ShowFront.length} Front display messages: </div>
                            </div>

                            <div className='playlist-items-container'>
                                {playList.ShowFront.map((message: any, index: any) => (
                                    <React.Fragment key={index}>
                                        <React.Fragment key={index}>
                                            <Message message={message} />
                                        </React.Fragment>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </>
                }
                {playList.ShowRear && playList.ShowRear.length > 0 &&
                    <>
                        <div className="playlist-list">
                            <div className='playlist-heading'>
                                <div className='heading'> {playList.ShowRear && playList.ShowRear.length} Rear display messages: </div>
                            </div>

                            <div className='playlist-items-container'>
                                {playList.ShowRear.map((message: any, index: any) => (
                                    <React.Fragment key={index}>
                                        <React.Fragment key={index}>
                                            <Message message={message} />
                                        </React.Fragment>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </>
                }
            </div>
        </div >
    );
};

export default PlaylistView;
