import React from 'react';
import PlaylistRow from './PlaylistRow';
import { KTSVG } from '../../../_metronic/helpers';

interface InputProps {
    playlists: any,
    messages: any,
    token: any,
    command: any
}


const MultiList: React.FC<InputProps> = ({ playlists, messages, token, command }) => {
    return (
        <>
            <div className='multilist-heading'>
                <div className='heading-container'>
                    <div className='heading'>
                        <span className='multilist'>
                            Multilist:&nbsp;
                        </span>
                        <span className='multilist-title'>
                            {command.Label}
                        </span>
                    </div>
                </div>
                <div className='multilist-description'>Showing playlists in multilist. Tap to view playlist content and info.</div>
                <div className='divider'></div>
            </div>

            {playlists && playlists.length > 0 && (
                <div className="multi-list">
                    <div className='multi-heading'>
                        <div className='multi'>
                            <div className='heading'> First message: </div>
                            <div className='heading'> Playlist Title: </div>
                        </div>
                        <div className='heading'> Properties: </div>
                    </div>
                    <div className='multi-items-container'>
                        {playlists?.map((playlist: any, i: any) => {
                            return <PlaylistRow token={token} key={`playlist${i}`} messages={messages} playlist={playlist} index={i} />
                        })}
                    </div>
                </div>
            )}
        </>
    )
}

export default MultiList
