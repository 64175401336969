import React, { useState } from 'react';
import './ShowCommandView.scss';
import { useLocation } from 'react-router-dom';
import { getMessagesWithJWT, getPlayListWithJWT, getShowCommandsWithJWT, getUserWithJWT } from '../../redux/api';
import MultiList from './MultiList';
import { KTSVG } from '../../../_metronic/helpers';

const ShowCommandView = () => {
    const location = useLocation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [command, setCommand] = useState<any>([])
    const [playlists, setPlaylists] = useState<any>([])
    const [messages, setMessages] = useState<any>([])
    const [token, setToken] = useState<any>(null);
    const [id, setId] = useState<any>(null);
    const [user, setUser] = useState<any>(null);

    const getTokenAndIdFromUrl = () => {
        const url: any = new URLSearchParams(location.search).toString();
        const keyValuePairs = url.split('&');
        let token: any = "";
        let id: any = "";
        keyValuePairs.forEach((pair: any) => {
            const [key, value] = pair.split('=');
            if (key === "token") {
                token = value;
            } else if (key === "id") {
                id = value.substring(2);
            }
        });
        return { token, id };
    };

    React.useEffect(() => {
        const { token, id } = getTokenAndIdFromUrl();
        setToken(token);
        setId(id);
        getUser(token, id);
    }, [])

    const getUser = (jwtToken: any, commandId?: any) => {
        setIsLoading(true);
        getUserWithJWT(jwtToken).then((res: any) => {
            if (res && res.Response) {
                const usr = res.Response[0]
                setUser(res.Response[0]);
                getCommand(jwtToken, usr.CustomerAccess[0], commandId)
            }
            setIsLoading(false);
        }).catch((e) => {
            setIsLoading(false)
        })
    }

    const extractValues = (arr: any) => {
        return arr.flatMap((obj: any) => {
            let arrayMessages: any = [];
            arr.forEach((obj: any) => {
                if (obj.hasOwnProperty("ShowFront")) {
                    arrayMessages.push(...Object.values(obj.ShowFront));
                }
                if (obj.hasOwnProperty("ShowRear")) {
                    arrayMessages.push(...Object.values(obj.ShowRear));
                }
                if (obj.hasOwnProperty("ShowAll")) {
                    arrayMessages.push(...Object.values(obj.ShowAll));
                }
            });
            return arrayMessages;
        });
    };

    const extractPlaylistIds = (data: any) => {
        return data.map((item: any) => item.PlaylistId);
    };

    const mergeArrays = (firstArray: any, secondArray: any) => {
        return firstArray.map((item: any) => {
            const match = secondArray.find((e: any) => e.PlaylistId === item.Id);
            if (match) {
                return { ...item, ...match };
            }
            return item;
        });
    };

    const getCommand = (jwtToken: any, customerId: any, commandId?: any, deviceGroupId?: any
    ) => {
        let reqData: any = {
            limit: 1,
            offset: 1,
            id: commandId
        }

        setIsLoading(true);

        getShowCommandsWithJWT(jwtToken, customerId, reqData)
            .then((res: any) => {
                if (res && res.Response) {
                    let cmmand = res.Response.Results[0]
                    setCommand(cmmand);
                    const ids = extractPlaylistIds(cmmand.Playlists);
                    getPlayLists(jwtToken, customerId, cmmand, ids)
                    getAllMessages(jwtToken, customerId)
                }
                setIsLoading(false)
            })
            .catch((e) => {
                setIsLoading(false)
            })
    }

    const getPlayLists = (jwtToken: any, customerId: any, command: any, playlistIds?: any, deviceGroupId?: any
    ) => {
        let reqData: any = {
            playlist_ids: playlistIds
        }

        setIsLoading(true);

        getPlayListWithJWT(jwtToken, customerId, reqData)
            .then((res: any) => {
                if (res && res.Response) {
                    let pls = res.Response.Results
                    const mergedArray = mergeArrays(pls, command.Playlists);
                    setPlaylists(mergedArray)
                }
                setIsLoading(false)
            })
            .catch((e) => {
                setIsLoading(false)
            })
    }

    const getAllMessages = (jwtToken: any, customerId: any
    ) => {
        let reqData: any = {
            limit: 99999,
            offset: 1
        }

        setIsLoading(true);

        getMessagesWithJWT(jwtToken, customerId, reqData)
            .then((res: any) => {
                if (res && res.Response) {
                    let allMessages = res.Response.Results
                    setMessages(allMessages)
                }
                setIsLoading(false)
            })
            .catch((e) => {
                setIsLoading(false)
            })
    }

    return (
        <div className="multilist-view">

            <div className="multilist-content">
                <div className='previous'>
                    <div className='previous-container' onClick={(e) => { window.history.back() }}>
                        <div className='icon'>
                            <KTSVG
                                path='/media/svg/general/previous.svg'
                                className='previous-icon'
                            />
                        </div>
                        <div className='title'>Configuration</div>
                    </div>
                </div>
                <>
                    {playlists && playlists.length > 0 &&
                        <MultiList playlists={playlists} token={token} messages={messages} command={command} />}
                </>

            </div>

            {command.Lifetime &&
                <div className='multilist-lifetime'>
                    <KTSVG
                        path='/media/svg/general/lifetime.svg'
                        className='svg-icon-lifetime'
                    />
                    <div className='lifetime-body'>
                        <div className='lifetime-container'>
                            <div className='label'>
                                Multilist lifetime:
                            </div>
                            <div className='value'>
                                {command.Lifetime ? `${command.Lifetime / 1000} seconds` : '-'}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default ShowCommandView;
