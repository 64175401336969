import React, { useRef, useState } from 'react'
import MessageRender from '../../modules/components/Message/MessageRender'
import MessageZones from '../../modules/message/components/MessageTable/MessageZones'

interface props {
    message: any
}

const Message: React.FC<props> = ({ message }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const rgbToColor = ({ r, g, b }: any) => {
        return `rgb(${r}, ${g}, ${b})`;
    };

    React.useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                document.documentElement.style.setProperty("--dynamic-width", `${containerRef.current.getBoundingClientRect().width}px`);
            }
        };

        updateWidth();

        window.addEventListener("resize", updateWidth);

        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, []);

    return (
        <>
            <div className="playlist-item" key={`message${message.Id}`}>
                <div className='playlist-item-container'>
                    <div ref={containerRef} className='message-container'>
                        {/* <div className='message-image'> */}
                        <div className={`message-image ${message.DynamicInputs && message.DynamicInputs.length > 0 ? '' : 'no-dynamic'}`} onClick={(e) => { e.stopPropagation() }}>
                            <MessageRender message={message} />
                        </div>
                        {message.DynamicInputs && message.DynamicInputs.length > 0 &&
                            <div className='dynamic'>
                                <div className='dynamic-symbol'>
                                    <div className='symbol'>
                                        [...]
                                    </div>
                                </div>
                                <div className='dynamic-text'>DYNAMIC</div>
                            </div>
                        }
                    </div>
                    <div className='message-data'>
                        <div className='data-item'>
                            <div className='item'>
                                <div className="label">Type:</div>
                                <div className="value">
                                    {message.Type ? message.Type.charAt(0).toUpperCase() + message.Type.slice(1) : '-'}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="label">Scroll:</div>
                                <div className="value">
                                    {message.ScrollDirection ? message.ScrollDirection.charAt(0).toUpperCase() + message.ScrollDirection.slice(1) : '-'}
                                </div>
                            </div>
                        </div>
                        <div className='data-item'>
                            <div className='item'>
                                <div className="label">Align:</div>
                                <div className="value">
                                    {message.Alignment ? message.Alignment.charAt(0).toUpperCase() + message.Alignment.slice(1) : '-'}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="label">Speed:</div>
                                <div className="value">
                                    {message.ScrollSpeed === 16 ? 'Fast'
                                        : message.ScrollSpeed === 20 ? 'Standard'
                                            : message.ScrollSpeed === 24 ? 'Slow'
                                                : message.ScrollSpeed ? message.ScrollSpeed : '-'}
                                </div>
                            </div>
                        </div>
                        <div className='data-item'>
                            <div className='item'>
                                <div className="label">Duration:</div>
                                <div className="value">
                                    {message.Duration ? `${message.Duration / 1000}s` : '-'}
                                </div>
                            </div>
                            <div className='item'>
                                <div className="label">Transition:</div>
                                <div className="value">
                                    {message.Transition === 'in' ? 'In'
                                        : message.Transition === 'out' ? 'Out'
                                            : message.Transition === 'in_out' ? 'In-Out'
                                                : message.Transition === 'in_pause_out' ? 'In-Pause-Out'
                                                    : message.Transition ? message.Transition : '-'}
                                </div>
                            </div>
                        </div>
                        <div className='data-item'>
                            <div className='item light-zones'>
                                <div className="label">Light zones:</div>
                                <div className="value">
                                    <MessageZones data={message} />
                                </div>
                            </div>
                            <div className='item w-100'>
                                <div className="label">Background:</div>
                                <div className='color-block'>
                                    <div className="color-palette" style={{ background: message.Background ? rgbToColor(message.Background) : 'rgb(0, 0, 0)' }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Message
