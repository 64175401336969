import axios from 'axios'
import storage from 'redux-persist/lib/storage'
import { clearCustomerList } from '../../db/Customersdb'

const API_URL = process.env.REACT_APP_API_ENDPOINT || 'api'

export const VERITY_EMAIL = `${API_URL}/prod/verifyEmail`
export const GET_GEO_STATUS = `${API_URL}/prod/api/a/getgeostatus/`
export const GET_CUSTOMER_LIST = `${API_URL}/prod/api/a/getCustomerList`
export const GET_DEVICE_LIST = `${API_URL}/prod/api/a/getDevices`
export const GET_STATUS_LOGS = `${API_URL}/prod/api/c2/getstatuslog/`
export const GET_MENU_SETTINGS = `${API_URL}/prod/api/a/getfrontendsettings`
export const UPLOAD_IMAGE = `${API_URL}/prod/api/a/uploadimage`
export const CUSTOMER = `${API_URL}/v2/customer`
export const CREATE_PLAYLIST = `${API_URL}/prod/api/a/createplaylist/`
export const UPDATE_PLAYLIST = `${API_URL}/v2/playlists`
export const CUSTOMERS = `${API_URL}/v2/customers`
export const CONFIGURATIONS = `${API_URL}/v2/configurations`
export const DEVICES = `${API_URL}/v2/devices`
export const DELETE_PLAYLIST = `${API_URL}/v2/playlists`
export const BITMAP_IMAGE_PREVIEW = `${API_URL}/v2/content/images/preview`
export const MESSAGES = `${API_URL}/v2/messages`
export const GENERAL = `${API_URL}/v2/general`
export const DEVICE_GROUPS = `${API_URL}/v2/device-groups`
export const DEVICE_GROUP = `${API_URL}/v2/device-group`
export const STORE = `${API_URL}/v2/store`
export const APP_SETTINGS = `${API_URL}/v2/app-settings`
export const DEVICE_SETTINGS = `${API_URL}/v2/device-settings`
export const GEOZONES = `${API_URL}/v2/geozones`
export const GEOZONE = `${API_URL}/v2/geozone`
export const PLAYLISTS = `${API_URL}/v2/playlists`
export const PNGTOPPM = `${API_URL}/v2/convertPNGToPPM`
export const USERS = `${API_URL}/v2/users`
export const CAMPAIGNS = `${API_URL}/v2/campaigns`
export const CAMPAIGN = `${API_URL}/v2/campaign`
export const ERRORLOGS = `${API_URL}/v2/error-logs`
export const LOGS = `${API_URL}/v2/logs`
export const PRODUCTS_SERVICES = `${API_URL}/v2/product-services`
export const SUBSCRIPTION = `${API_URL}/v2/subscription`
export const LOGIN = `${API_URL}/prod/api/users/login`
export const GEO = `${API_URL}/v2/geo`
export const BOOKING = `${API_URL}/v2/booking`
export const TRIGGERS = `${API_URL}/v2/triggers`
export const FACTORY = `${API_URL}/v2/factory`


// Server should return AuthModel
export function verifyEmail(data: any) {
  return axios.post(VERITY_EMAIL, data)
}

export function getGeoStatus(data: any) {
  return axios.post(GET_GEO_STATUS, data)
}

export function getGeoZones(data: any) {
  const prams = new URLSearchParams(data).toString();
  const url = `${GEOZONES}?${prams}`;
  return axios.get(url)
}

export function createGeoZone(data: any) {
  return axios.post(GEOZONE, data)
}

export function updateGeoZone(data: any) {
  const url = `${GEOZONES}/${data.Id}`;
  return axios.put(url, data)
}

export function deleteGeoZone(Id: number) {
  return axios.delete(`${GEOZONES}/${Id}`)
}

export function getCustomerList() {
  return axios.get(CUSTOMERS)
}

export function apiGetFactoryCustomers() {
  const url = `${FACTORY}/customers`;

  return axios.post(url)
}


export function apiFactoryTransferDevice(data: any) {
  const url = `${FACTORY}/transfer-devices`;

  return axios.post(url, data)
}

export function getMenuSettings() {
  return axios.get(GET_MENU_SETTINGS)
}

export function getStatusLogs(data: any) {

  const prams = new URLSearchParams(data).toString();
  const url = `${GET_STATUS_LOGS}?${prams}`;

  return axios.get(url)
}

export function getDevicesList(data: any) {
  return axios.post(GET_DEVICE_LIST, data)
}

export function getListmessages(data: any = null) {
  const url = `${CUSTOMERS}/${data.CustomerId}/messages/?limit=${data.limit}`;
  return axios.get(url)
}

export function getDeviceInfo(data: any = null) {
  // const data = {
  //   // CustomerIds: [],
  //   // DeviceGroupID: [],
  //   id
  // }
  // const prams = new URLSearchParams(data).toString();

  // return axios.get(`${DEVICES}?${prams}`)
  return axios.get(`${CUSTOMERS}/${data.customerId}/devices?id=${data.id}`)

}

export function createPlaylist(data: any) {
  return axios.post(CREATE_PLAYLIST, data)
}

export function updatePlayList(data: any, id: any) {
  return axios.put(`${UPDATE_PLAYLIST}/${id}`, data)
}

export function getPlayList(customerId: any, data: any) {
  const prams = new URLSearchParams(data).toString();
  const url = `${CUSTOMERS}/${customerId}/playlists?${prams}`;
  return axios.get(url)
}

// export function getPlayListByDeviceGroup(customerId: any, deviceGroupId: any, limit: number, offset: number) {
//   const url = `${CUSTOMERS}/${customerId}/playlists?device_group_id=${deviceGroupId}&limit=${limit}&offset=${offset}`;
//   return axios.get(url)
// }

// export function getPlayListById(customerId: any, playlistId: any) {
//   const url = `${CUSTOMERS}/${customerId}/playlists?playlist_ids=${playlistId}`;
//   return axios.get(url)
// }

export function deletePlayList(Id: number) {
  return axios.delete(`${DELETE_PLAYLIST}/${Id}`)
}

export function getStatistics(customerId: number) {
  return axios.get(`${CUSTOMERS}/${customerId}/statistics`)
}

export function uploadImage(data: any = {}) {

  const formData: any = new FormData();
  formData.append('File', data.file);
  formData.append('Description', data.Description);
  formData.append('CustomerId', data.CustomerId);
  formData.append('Overwrite', true);

  return axios.post(UPLOAD_IMAGE, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function getMessageList(customerId: number, data: any) {
  const prams = new URLSearchParams(data).toString();
  return axios.get(`${CUSTOMERS}/${customerId}/messages?${prams}`)
}

export function deleteMessage(messageId: number) {
  return axios.delete(`${MESSAGES}/${messageId}`)
}

export function getConfigurations(customerId: number, deviceGroupsId: number, active: boolean, limit: number, offset: number) {
  return axios.get(`${CUSTOMERS}/${customerId}/configurations?device_groups_ids=${deviceGroupsId}&active=${active}&limit=${limit}&offset=${offset}`)
}

export function getDevicesByQuery(data: any) {
  const prams = new URLSearchParams(data).toString();
  return axios.get(`${DEVICES}?${prams}`)
}

export function getDevicesByCustomer(data: any, query: any) {
  const prams = new URLSearchParams(query).toString();
  return axios.get(`${CUSTOMERS}/${data.customerId}/devices?${prams}`)
}

export function sendTestToDevice(deviceId: any, playlistId: any, payload?: any) {
  return axios.post(`${PLAYLISTS}/${playlistId}/show`, payload)
}

export function addDevice(data: any) {
  return axios.post(`${DEVICES}`, data)
}

export function updateDevice(id: number, data: any) {
  return axios.put(`${DEVICES}/${id}`, data)
}

export function configurationCrudApi(id: number, data: any) {
  return axios.put(`${CONFIGURATIONS}/${id}`, data)
}

export function imagePreview(data: any, file?: any) {

  const formData: any = new FormData();

  if (file) {
    formData.append('ImageFile', file);
  }

  Object.keys(data).map((key: any, i: any) => {
    formData.append(key, data[key]);
  })

  return axios.post(`${BITMAP_IMAGE_PREVIEW}`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function createMessage(data: any, file?: any) {

  const formData: any = new FormData();

  if (file) {
    formData.append('ImageFile', file);
  }


  Object.keys(data).map((key: any, i: any) => {
    formData.append(key, data[key]);
  })

  // formData.append('body', JSON.stringify(data));

  return axios.post(MESSAGES, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function createCategory(data: any, iconFile?: any, headerFile?: any) {

  const formData: any = new FormData();

  if (iconFile) {
    formData.append('IconImage', iconFile);
  }
  if (headerFile) {
    formData.append('HeaderImage', headerFile);
  }

  Object.keys(data).map((key: any, i: any) => {
    formData.append(key, data[key]);
  })

  return axios.post(`${STORE}/categories/`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function updateCategory(id: number, data: any, iconFile?: any, headerFile?: any) {

  const formData: any = new FormData();

  if (iconFile) {
    formData.append('IconImage', iconFile);
  }
  if (headerFile) {
    formData.append('HeaderImage', headerFile);
  }

  Object.keys(data).map((key: any, i: any) => {
    formData.append(key, data[key]);
  })

  return axios.put(`${STORE}/categories/${id}`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function updateMessage(id: number, data: any, file?: any) {

  const formData: any = new FormData();

  if (file) {
    formData.append('ImageFile', file);
  }


  Object.keys(data).map((key: any, i: any) => {
    formData.append(key, data[key]);
  })

  return axios.put(`${MESSAGES}/${id}`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}


export function getStoreCategory() {
  return axios.get(`${STORE}/categories`)
}

export function deleteStoreCategory(categoryId: number) {
  return axios.delete(`${STORE}/categories/${categoryId}`)
}

export function createStore(category: number, playlistId: number, data: any) {
  return axios.post(`${STORE}/categories/${category}/add-content/${playlistId}`, data)
}

export function ApiUpdateStore(content: number, data: any) {
  return axios.put(`${STORE}/content/${content}`, data)
}

export function getLanguages() {
  return axios.get(`${GENERAL}/languages`)
}

export function buyStoreItem(id: number, data: any) {
  return axios.post(`${STORE}/${id}/buy`, data)
}

export function createCustomer(data: any) {
  return axios.post(`${CUSTOMERS}`, data)
}

export function getTimezone() {
  return axios.get(`${GENERAL}/time-zones`)
}

export function getCommands(id: number, data?: any) {
  const prams = new URLSearchParams(data).toString();
  return axios.get(`${CUSTOMERS}/${id}/show-commands?${prams}`)
}

export function apiGetTriggers(data: any) {
  const prams = new URLSearchParams(data).toString();
  return axios.get(`${TRIGGERS}/?${prams}`)
}

export function updateCommand(id: any, data?: any) {
  return axios.put(`${API_URL}/v2/show-commands/${id}`, data)
}

export function createCommand(id: any, data?: any) {
  return axios.post(`${CONFIGURATIONS}/${id}/show-commands`, data)
}

export function reorderCommand(id: number, data: any) {
  return axios.post(`${CONFIGURATIONS}/${id}/show-commands/reorder`, data)
}

export function deleteCommand(id: number) {
  return axios.delete(`${API_URL}/v2/show-commands/${id}`)
}

export function getSchedule(id: any, data?: any) {
  const prams = new URLSearchParams(data).toString();
  return axios.get(`${CONFIGURATIONS}/${id}/schedules/?${prams}`)
}

export function getConfigurationStatusReport(id: any) {
  return axios.get(`${CONFIGURATIONS}/${id}/statusreport`)
}

export function deleteSchedule(id: any) {
  return axios.delete(`${API_URL}/v2/schedules/${id}`)
}

export function createSchedule(id: any, data: any) {
  return axios.post(`${CONFIGURATIONS}/${id}/schedules`, data)
}

export function updateSchedule(id: any, data: any) {
  return axios.put(`${API_URL}/v2/schedules/${id}`, data)
}

export function createDeviceGroup(data: any) {
  return axios.post(`${DEVICE_GROUPS}`, data)
}

export function updateDeviceGroup(deviceGroup: any, data: any) {
  return axios.put(`${DEVICE_GROUPS}/${deviceGroup}`, data)
}

export function deleteDeviceGroup(deviceGroup: any) {
  return axios.delete(`${DEVICE_GROUP}/${deviceGroup}`)
}

export function transferDevice(deviceGroup: any, data: any) {
  return axios.post(`${DEVICE_GROUPS}/${deviceGroup}/transfer-devices`, data)
}

export function transferPlaylist(customerId: number, data: any) {
  return axios.post(`${CUSTOMERS}/${customerId}/transfer-playlists`, data)
}

export function transferMessage(customerId: number, data: any) {
  return axios.post(`${CUSTOMERS}/${customerId}/transfer-messages`, data)
}

export function getStore(data: any) {

  const prams = new URLSearchParams(data).toString();
  const url = `${STORE}/content?${prams}`;

  return axios.get(url)

}

export function getAppSettings(data?: any) {
  const prams = new URLSearchParams(data).toString();
  return axios.get(`${APP_SETTINGS}?${prams}`)
}

export function saveAppSetting(id: number, data?: any) {
  return axios.put(`${APP_SETTINGS}/${id}`, data)
}

export function getDeviceSetting(data?: any) {
  const prams = new URLSearchParams(data).toString();
  return axios.get(`${DEVICE_SETTINGS}?${prams}`)
}

export function saveDeviceSetting(id: number, data?: any) {
  return axios.put(`${DEVICE_SETTINGS}/${id}`, data)
}

export function KafkaSendMessage(data: any) {
  return axios.post(`${API_URL}/v2/kafka/send_message/`, data)
}

export function convertPNGToPPM(params: any, file: any) {
  const prams = new URLSearchParams(params).toString();
  const formData: any = new FormData();

  if (file) {
    formData.append('PNGFile', file);
  }

  return axios.post(`${PNGTOPPM}?${prams}`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function sendTemporaryMessage(id: number, data: any, file: any) {

  const formData: any = new FormData();

  if (file) {
    formData.append('ImageFile', file);
  }

  Object.keys(data).map((key: any, i: any) => {
    formData.append(key, data[key]);
  })

  // return axios.post(`${DEVICES}/${id}/temporarymessage`, formData, {
  return axios.post(`${API_URL}/v2/temporarymessage`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })

}

export function getUser(data: any) {
  const prams = new URLSearchParams(data).toString();
  return axios.get(`${USERS}?${prams}`)
}

export function getCampaigns(data: any) {
  const prams = new URLSearchParams(data).toString();
  return axios.get(`${CAMPAIGNS}?${prams}`)
}

export function getErrorLogs(data: any) {
  const prams = new URLSearchParams(data).toString();
  const url = `${ERRORLOGS}?${prams}`;
  return axios.get(url)
}

export function getUsers() {
  return axios.get(`${USERS}`)
}

export function login(UserName: string, Password: string) {
  return axios.post(`${LOGIN}`, { UserName, Password })
}

export function updateUser(id: any, data: any, image?: any) {
  const formData: any = new FormData();

  if (image) {
    formData.append('Image', image);
  }

  Object.keys(data).map((key: any, i: any) => {
    formData.append(key, data[key]);
  })

  return axios.put(`${USERS}/${id}`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function updateCustomer(id: any, data: any, image?: any) {
  const formData: any = new FormData();

  if (image) {
    formData.append('Image', image);
  }

  Object.keys(data).map((key: any, i: any) => {
    formData.append(key, data[key]);
  })

  return axios.put(`${CUSTOMERS}/${id}`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function createCreative(campaignId: any, data: any, image?: any) {
  const formData: any = new FormData();

  if (image) {
    formData.append('Image', image);
  }

  Object.keys(data).map((key: any, i: any) => {
    formData.append(key, data[key]);
  })

  return axios.post(`${CAMPAIGNS}/${campaignId}/creatives`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function createCampaign(data: any, coverImage?: any, headerImage?: any) {
  const formData: any = new FormData();

  if (coverImage) {
    // formData.append('CoverImage', image);
  }

  if (headerImage) {
    formData.append('Image', headerImage);
  }

  Object.keys(data).map((key: any, i: any) => {
    formData.append(key, data[key]);
  })

  return axios.post(`${CAMPAIGNS}`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function updateCampaign(campaignId: any, data: any, coverImage?: any, headerImage?: any) {
  const formData: any = new FormData();

  if (coverImage) {
    // formData.append('CoverImage', image);
  }

  if (headerImage) {
    formData.append('Image', headerImage);
  }

  Object.keys(data).map((key: any, i: any) => {
    formData.append(key, data[key]);
  })

  return axios.put(`${CAMPAIGNS}/${campaignId}`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
}

export function deleteCreative(Id: number) {
  return axios.delete(`${CAMPAIGNS}/creatives/${Id}`)
}

export function getServicesAndProducts(data: any) {
  const prams = new URLSearchParams(data).toString();
  return axios.get(`${PRODUCTS_SERVICES}?${prams}`)
}

export function purchaseServiceOrProduct(data: any) {
  const body = { "CustomerId": data.customerId }
  return axios.post(`${PRODUCTS_SERVICES}/${data.id}/purchase-credits`, body)
}

export function getPurchaseStatus(id: any, prms?: any) {
  const prams = new URLSearchParams(prms).toString();
  return axios.get(`${PRODUCTS_SERVICES}/purchase/${id}?${prams}`)
}

export function createSubscription(data: any) {
  const prams = new URLSearchParams(data).toString();
  return axios.post(`${SUBSCRIPTION}?${prams}`)
}

export function updateSubscription(customreId: any, id: any) {
  return axios.put(`${CUSTOMER}/${customreId}/subscription/${id}`)
}

export function ApiGetGeocoding(prms?: any) {
  const prams = new URLSearchParams(prms).toString();
  return axios.get(`${GEO}/geocoding/?${prams}`)
}

export function ApiGetReverseGeocoding(prms?: any) {
  const prams = new URLSearchParams(prms).toString();
  return axios.get(`${GEO}/reverse-geocoding/?${prams}`)
}

export function ApiCreateBooking(booking: any) {

  return axios.post(`${BOOKING}`, booking)
}

export function ApiGetDeviceLogs(data: any) {
  const prams = new URLSearchParams(data).toString();
  const url = `${LOGS}/devices/?${prams}`;
  return axios.get(url)
}

export async function fetchCoordinates(data: any) {
  const prams = new URLSearchParams(data).toString();

  const url = `https://nominatim.openstreetmap.org/search?${prams}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {}
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error fetching coordinates:', error);
    throw error;
  }
}

export async function getConfigurationsWithJWT(jwtToken: any, customerId: number, data: any) {
  const prams = new URLSearchParams(data).toString();
  const url = `${CUSTOMERS}/${customerId}/configurations?${prams}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'access-token': jwtToken,
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error fetching configuration:', error);
    throw error;
  }
}

export async function getPlayListWithJWT(jwtToken: any, customerId: any, data: any) {
  const prams = new URLSearchParams(data).toString();
  const url = `${CUSTOMERS}/${customerId}/playlists?${prams}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'access-token': jwtToken,
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error fetching playlist:', error);
    throw error;
  }
}

export async function getMessagesWithJWT(jwtToken: any, customerId: any, data: any) {
  const prams = new URLSearchParams(data).toString();
  const url = `${CUSTOMERS}/${customerId}/messages?${prams}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'access-token': jwtToken,
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error;
  }
}

export async function getShowCommandsWithJWT(jwtToken: any, customerId: any, data: any) {
  const prams = new URLSearchParams(data).toString();
  const url = `${CUSTOMERS}/${customerId}/show-commands?${prams}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'access-token': jwtToken,
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error fetching show-commands:', error);
    throw error;
  }
}

export async function getUserWithJWT(jwtToken: any) {
  const url = `${USERS}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'access-token': jwtToken,
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }
}

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status == 401) {
    localStorage.removeItem('k')
    localStorage.removeItem('p')
    localStorage.removeItem('customerId')
    localStorage.removeItem('group')
    // localStorage.removeItem('customerList')
    clearCustomerList();
    storage.removeItem('persist:v1-dhg-auth')
    window.location.href = "/"
  }
  return error;
});