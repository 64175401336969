import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import AddPlayListTable from './AddPlaylistTable/AddPlayListTable'

interface inputProps {
  isOpenPlaylistModal: any
  handlePlaylistModal: () => void,
  addPlaylist: (data: any) => void,
  selectedDeviceGroup: any,
  playLists: any,
  inputSelect?: any,
  selectedList: any,
  title: any,
  description?: any
  setSelectedList?: any
}

const AddPlayListModal: React.FC<inputProps> = ({ isOpenPlaylistModal, description, setSelectedList, selectedDeviceGroup, handlePlaylistModal, addPlaylist, playLists, selectedList, title, inputSelect }) => {

  return (
    <>
      <Modal size='xl' show={isOpenPlaylistModal} onHide={handlePlaylistModal}>
        <Modal.Body>
          <div className='d-flex w-100 justify-content-between'>
            <h1 className='align-items-start d-flex flex-column'>
              <span className='card-label fw-bolder text-primary d-block'>{title}</span>
              {description}
            </h1>
            <button className='btn p-0 h-100' onClick={handlePlaylistModal}>
              <i className="fa fa-times-thin"
                aria-hidden='true'
                style={{ fontSize: '2em', padding: 0, verticalAlign: 'unset' }}></i>
            </button>
          </div>
          <AddPlayListTable playLists={playLists} inputSelect={inputSelect} selector={"PlaylistId"} handlePlaylistModal={handlePlaylistModal} addPlaylist={addPlaylist} selectedList={selectedList}
            setSelectedList={setSelectedList}></AddPlayListTable>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddPlayListModal
