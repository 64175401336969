import React, { useState } from 'react';
import './ConfigurationView.scss';
import { getMessagesWithJWT, getPlayListWithJWT, getShowCommandsWithJWT, getUserWithJWT } from '../../redux/api';
import _ from 'lodash';
import CommandRow from './CommandRow';
import { useLocation } from 'react-router-dom';

const ConfigurationView = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    // const [playLists, setPlayList] = useState<any>([])
    const [messages, setMessages] = useState<any>([])
    const [token, setToken] = useState<any>(null);
    const [user, setUser] = useState<any>(null);
    const location = useLocation()
    const [commands, setCommands] = useState<any>([])

    const getTokenFromUrl = () => {
        const url: any = new URLSearchParams(location.search).toString();
        const token: any = url.replace("token=", "");
        return token;
    };

    React.useEffect(() => {
        const token: any = getTokenFromUrl();
        setToken(token);
        getUser(token);
    }, [])

    const getUser = (jwtToken: any) => {
        setIsLoading(true);
        getUserWithJWT(jwtToken).then((res: any) => {
            if (res && res.Response) {
                const usr = res.Response[0]
                setUser(res.Response[0]);
                getCommands(jwtToken, usr.CustomerAccess[0]);
                getAllMessages(jwtToken, usr.CustomerAccess[0])
            }
            setIsLoading(false);
        }).catch((e) => {
            setIsLoading(false)
        })
    }

    function mergePlaylistDetails(commandsArray: any, playlistslist: any) {
        const playlistMap: any = new Map(playlistslist.map((pl: any) => [pl.Id, pl]));

        return commandsArray.map((cmd: any) => {
            const updatedPlaylists = cmd.Playlists.map((pl: any) => {
                return { ...pl, ...playlistMap.get(pl.PlaylistId) };
            });
            return { ...cmd, Playlists: updatedPlaylists };
        });
    }

    const getCommands = (jwtToken: any, customerId: any, commandId?: any, deviceGroupId?: any
    ) => {
        let reqData: any = {
            limit: 99999,
            offset: 1,
        }

        setIsLoading(true);

        getShowCommandsWithJWT(jwtToken, customerId, reqData)
            .then((res: any) => {
                if (res && res.Response) {
                    let cmmands = res.Response.Results
                    const sortedCommnads = _.sortBy((cmmands), 'OrderInApp')
                    getAllPlayList(jwtToken, customerId, sortedCommnads);
                    // const ids = extractPlaylistIds(cmmand.Playlists);
                    // getPlayLists(jwtToken, customerId, cmmand, ids)
                    // getAllMessages(jwtToken, customerId)
                }
                setIsLoading(false)
            })
            .catch((e) => {
                setIsLoading(false)
            })
    }

    const getAllPlayList = (jwtToken: any, customerId: any, commandsArray: any, deviceGroupId?: any
    ) => {
        let reqData: any = {
            limit: 99999,
            offset: 1
        }

        setIsLoading(true);

        getPlayListWithJWT(jwtToken, customerId, reqData)
            .then((res: any) => {
                if (res && res.Response) {
                    // setPlayList(res.Response.Results)
                    let playlists = res.Response.Results
                    let cmmands = mergePlaylistDetails(commandsArray, playlists);
                    setCommands(cmmands);
                }
                setIsLoading(false)
            })
            .catch((e) => {
                setIsLoading(false)
            })
    }

    const getAllMessages = (jwtToken: any, customerId: any
    ) => {
        let reqData: any = {
            limit: 99999,
            offset: 1
        }

        setIsLoading(true);

        getMessagesWithJWT(jwtToken, customerId, reqData)
            .then((res: any) => {
                if (res && res.Response) {
                    let allMessages = res.Response.Results
                    setMessages(allMessages)
                }
                setIsLoading(false)
            })
            .catch((e) => {
                setIsLoading(false)
            })
    }

    // const getPlaylist = (id: number) => {
    //     const playlist: any = _.find(playLists, { Id: id });
    //     return playlist;
    // }

    return (
        <div className="configuration-view">
            {/* <div className="header bg-primary">
                <img className="logo ml-auto" src={user?.AvatarURL} alt="Logo" />
            </div> */}
            <div className="configuration-content">

                <div className='configuration-heading'>
                    <div className='heading-container'>
                        <div className='heading'>
                            <span className='configuration'>
                                Configuration:&nbsp;
                            </span>
                            <span className='configuration-title'>
                                {user?.Company} Configuration
                            </span>
                        </div>
                    </div>
                    <div className='configuration-description'>Showing all playlists, multilists and app control buttons. Tap to view content.</div>
                    <div className='divider'></div>
                </div>

                <div className="config-list">
                    {commands && commands.length > 0 && (
                        <>                            
                            <div className='config-heading'>
                                <div className='config'>
                                    <div className='heading'> First message: </div>
                                    <div className='heading'> Playlist Title: </div>
                                </div>
                                <div className='heading'> Properties: </div>
                            </div>
                            <div className='config-items-container'>
                                {commands.map((command: any, i: any) => {
                                    return <CommandRow token={token} key={`command${i}`} messages={messages} command={command} index={i} />
                                })}
                            </div>
                        </>
                    )}
                </div>
            </div>
            {/* <div className="footer bg-primary">
            </div> */}
        </div>
    );
};

export default ConfigurationView;
