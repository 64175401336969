import clsx from 'clsx'
import React, { useState } from 'react'
import MessageInfoModal from '../../message/components/MessageTable/MessageInfoModal'
import PlaylistMessageZones from '../../playlist/components/PlaylistMessageZones'
import MessageBitmap from './MessageBitmap'
import MessageFullImagePreview from './MessageFullImagePreview'
import MessageImage from './MessageImage'
import MessageText from './MessageText'
import CreditIcon from '../../credits/Components/CreditIcon'
import { Badge } from 'react-bootstrap'

const MessageRender: React.FC<any> = ({ message, showZone, pushByRightClick, isMessageInfo, height, showStore }) => {

    const [showFull, setShowFull] = useState<boolean>(false);
    const [pushedByClick, setPushedByClick] = useState<boolean>(false);
    const [isOpenInfoModal, setIsInfoModal] = useState(false);
    const [selectedMessage, setSelectedMessage]: any = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleCloseInfoModal = () => {
        setIsInfoModal(false);
        setSelectedMessage(null);
    }

    const handleOpenInfoModal = (message: any) => {
        setSelectedMessage(message);
        setIsInfoModal(true);
    }

    const rightClick = (e: any) => {
        if (pushByRightClick) {
            pushByRightClick(e, message);
            setPushedByClick(true);
            setTimeout(() => {
                setPushedByClick(false);
            }, 500);
        }
    }

    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        if (event.currentTarget.naturalWidth > 180) {
            setIsExpanded(true);
        }
    };

    if (message.PreviewUrl)
        return <>
            {showZone &&
                <PlaylistMessageZones data={message}></PlaylistMessageZones>
            }
            <div className={clsx("image-preview animate__animated", { "animate__backOutUp": pushedByClick, "animate__fadeInk": !pushedByClick, "align-items-center": showStore })} onContextMenu={rightClick}>
                <div className={'msg-parent'} >
                    <img alt='' onLoad={handleImageLoad} height={height ? height : '48px'} onClick={() => { if (isMessageInfo) handleOpenInfoModal(message) }} style={{ width: 'auto' }} src={`${message.PreviewUrl}?id=${Math.random().toString(36).slice(2)}`} ></img>
                    {isExpanded &&
                        <div className='img-expand'>
                            <div className='img-dots' onClick={(e) => { e.stopPropagation(); setShowFull(!showFull) }}>
                                <div>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <MessageFullImagePreview show={showFull} close={() => setShowFull(false)} imageUrl={`${message.PreviewUrl}?id=${Math.random().toString(36).slice(2)}`}></MessageFullImagePreview>
                <MessageInfoModal isOpenInfoModal={isOpenInfoModal} handleCloseInfoModal={handleCloseInfoModal} message={selectedMessage} fullInfo={false} />
                {(showStore && message.Store) && <>
                    <Badge className='bootup-badge ms-2' bg="store" style={{ height: 'fit-content' }}>
                        <CreditIcon />
                        <span className='store-text'>Store</span>
                    </Badge>
                </>}
            </div >
        </>

    if (message.ImageUrl)
        return <img height={height ? height : '48px'} alt="" style={{ width: 'auto' }} src={`${message.ImageUrl}`} ></img>

    let child = <></>;
    if (message.Type === 'text') {
        child = <MessageText message={message} showZone={showZone}></MessageText>
    } else if (message.Type === 'bitmap') {
        child = <MessageBitmap message={message} showZone={showZone}></MessageBitmap>
    } else if (message.Type === 'image') {
        child = <MessageImage message={message} showZone={showZone}></MessageImage>
    }

    if (showZone) {
        return child;
    } else {
        return <>
            <div className={`mb-3`} style={{ display: 'inline-block', width: 'auto', marginRight: "10px" }} >{child}</div>
        </>
    }
}

export default MessageRender;
