import React from 'react';
import { useNavigate } from 'react-router-dom';
import MessageRender from '../../modules/components/Message/MessageRender';
import { KTSVG } from '../../../_metronic/helpers';

interface InputProps {
  playlist: any,
  messages: any,
  index: number,
  token: any,
}

const PlaylistRow: React.FC<InputProps> = ({ playlist, index, token, messages }) => {
  const navigate = useNavigate();

  const firstMessage = playlist?.ShowAll?.[0] ??
    playlist?.ShowFront?.[0] ??
    playlist?.ShowRear?.[0] ??
    [];

  const extractMessagesIds = (obj: any) => {
    let arrayMessages: any = [];
    if (obj.hasOwnProperty("ShowFront")) {
      arrayMessages.push(...Object.values(obj.ShowFront));
    }
    if (obj.hasOwnProperty("ShowRear")) {
      arrayMessages.push(...Object.values(obj.ShowRear));
    }
    if (obj.hasOwnProperty("ShowAll")) {
      arrayMessages.push(...Object.values(obj.ShowAll));
    }
    return arrayMessages.map((item: any) => item.MessageId);
  };

  const getTotalMessages = (playlists: any) => {
    let totalMessages = 0;

    if (playlist.ShowFront) {
      totalMessages += playlist.ShowFront.length;
    }
    if (playlist.ShowRear) {
      totalMessages += playlist.ShowRear.length;
    }
    if (playlist.ShowAll) {
      totalMessages += playlist.ShowAll.length;
    }

    // command.TotalMessages = totalMessages;

    return totalMessages;
  };

  const isDynamic = () => {
    const ids = extractMessagesIds(playlist);
    const filterMessagesByIds = messages.filter((message: any) => ids.includes(message.Id));
    const hasDynamicInputs: boolean = filterMessagesByIds.some((message: any) => message.DynamicInputs.length > 0);
    return hasDynamicInputs;
  }

  return (
    <>
      {playlist &&
        <div className="multi-item" key={index} onClick={(e) => {
          localStorage.setItem('pTitle', 'View Multilist');
          navigate(`/mobile/playlist?token=${token}&id=${playlist.Id}`)
        }}>
          {/* <img src="vacant-logo.png" alt="Vacant" /> */}
          <div className='multi-item-container'>
            <div className='message-image' onClick={(e) => { e.stopPropagation() }}>
              <MessageRender message={firstMessage} />
            </div>
            <div className='multi-title'>
              {String(playlist?.Title)}
              {/* <p className='mobile-content'>{(playlist?.ShowAll?.length ?? 0) + (playlist?.ShowFront?.length ?? 0) + (playlist?.ShowRear?.length ?? 0)} {(playlist?.ShowAll?.length ?? 0) + (playlist?.ShowFront?.length ?? 0) + (playlist?.ShowRear?.length ?? 0) > 1 ? ' messages' : ' message'}</p> */}
              {/* <p className='mobile-content'>{getTotalMessages(playlist)} {getTotalMessages(playlist) > 1 ? ' messages' : ' message'}{playlist.length > 1 ? ', Multilist' : ''}</p>
            <p className='mobile-content'>{isDynamic() ? 'Dynamic' : ''}</p> */}
            </div>
          </div>
          {/* <div className='multi-duration mobile-content mr-6'>
            <div className='property'>
              <KTSVG
                path='/media/svg/general/duration.svg'
                className='svg-icon-1 svg-icon-command'
              />
              {playlist.Duration > 0 ?
                <p className='property-text'>
                  {playlist.Duration > 0 ? playlist.Duration / 1000 : playlist.Duration}s
                </p> :
                <p className='property-text'>
                  -
                </p>
              }
            </div>
          </div> */}
          {/* <div className='multi-properties tablet-content'> */}
          {/* <h2 className="">{playlist.Duration ? `${playlist.Duration / 1000}s` : '-'}</h2> */}
          {/* {playlist.Duration > 0 &&
              <div className='property'>
                <KTSVG
                  path='/media/svg/general/duration.svg'
                  className='svg-icon-1 svg-icon-command'
                />
                <p className='property-text'>
                  {playlist.Duration > 0 ? playlist.Duration / 1000 : playlist.Duration}s
                </p>
              </div>
            }
            {
              isDynamic() &&
              <div className='property'>
                <KTSVG
                  path='/media/svg/general/dynamic.svg'
                  className='svg-icon-1 svg-icon-command'
                />
                <p className='property-text'>
                  DYN
                </p>
              </div>
            }
            {getTotalMessages(playlist) > 0 &&
              <div className='property'>
                <KTSVG
                  path='/media/svg/general/messages.svg'
                  className='svg-icon-1 svg-icon-command'
                />
                <p className='property-text'>
                  {getTotalMessages(playlist)}
                </p>
              </div>
            }
          </div> */}
          <div className='multi-properties-tablet'>
            <div className='properties'>
              {playlist.Duration > 0 &&
                <div className='property'>
                  <KTSVG
                    path='/media/svg/general/lifetime.svg'
                    className='svg-icon-command'
                  />
                  <div className='property-text'>
                    {playlist.Duration > 0 ? playlist.Duration / 1000 : '-'}s
                  </div>
                </div>
              }
              {/* {command.Playlists.length > 1 &&
                <div className='property'>
                  <KTSVG
                    path='/media/svg/general/multilist.svg'
                    className='svg-icon-command'
                  />
                  <div className='property-text'>
                    MULTI
                  </div>
                </div>
              } */}
              {isDynamic() &&
                <div className='property'>
                  <KTSVG
                    path='/media/svg/general/dynamic.svg'
                    className='svg-icon-command'
                  />
                  <div className='property-text'>
                    DYN
                  </div>
                </div>
              }
              {getTotalMessages(playlist) > 0 &&
                <div className='property'>
                  <KTSVG
                    path='/media/svg/general/messages.svg'
                    className='svg-icon-command'
                  />
                  <div className='property-text'>
                    {getTotalMessages(playlist)}
                  </div>
                </div>
              }
            </div>
          </div>
          <div className='multi-properties-mobile'>
            {getTotalMessages(playlist) > 0 &&
              <div className='property'>
                <div className='property-text'>
                  {getTotalMessages(playlist)}
                </div>
                <KTSVG
                  path='/media/svg/general/messages.svg'
                  className='svg-icon-command'
                />
              </div>
            }
            {isDynamic() &&
              <div className='property'>
                <div className='property-text'>
                  DYN
                </div>
                <KTSVG
                  path='/media/svg/general/dynamic.svg'
                  className='svg-icon-command'
                />
              </div>
            }
            {/* {command.Playlists.length > 1 &&
              <div className='property'>
                <div className='property-text'>
                  MULTI
                </div>
                <KTSVG
                  path='/media/svg/general/multilist.svg'
                  className='svg-icon-command'
                />
              </div>
            } */}
            {playlist.Duration > 0 &&
              <div className='property'>
                <div className='property-text'>
                  {playlist.Duration > 0 ? playlist.Duration / 1000 : '-'}s
                </div>
                <KTSVG
                  path='/media/svg/general/lifetime.svg'
                  className='svg-icon-command'
                />
              </div>
            }
          </div>
        </div>
      }
    </>
  )
}

export default PlaylistRow
