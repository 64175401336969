import React from 'react';
import { useNavigate } from 'react-router-dom';
import MessageRender from '../../modules/components/Message/MessageRender';
import { KTSVG } from '../../../_metronic/helpers';

interface InputProps {
  command: any,
  messages: any,
  index: number,
  token: any
}

const CommandRow: React.FC<InputProps> = ({ index, command, token, messages }) => {
  const navigate = useNavigate();
  let playlist = command.Playlists[0];

  const firstMessage = playlist?.ShowAll?.[0] ??
    playlist?.ShowFront?.[0] ??
    playlist?.ShowRear?.[0] ??
    [];

  const extractMessagesIds = (playlists: any) => {
    let arrayMessages: any = [];
    // let totalMessages = 0;

    playlists.forEach((playlist: any) => {
      if (playlist.ShowFront) {
        arrayMessages.push(...playlist.ShowFront);
        // totalMessages += playlist.ShowFront.length;
      }
      if (playlist.ShowRear) {
        arrayMessages.push(...playlist.ShowRear);
        // totalMessages += playlist.ShowRear.length;
      }
      if (playlist.ShowAll) {
        arrayMessages.push(...playlist.ShowAll);
        // totalMessages += playlist.ShowAll.length;
      }
    });

    // command.TotalMessages = totalMessages;

    return arrayMessages.map((item: any) => item.MessageId);
  };

  const getTotalMessages = (playlists: any) => {
    let totalMessages = 0;

    playlists.forEach((playlist: any) => {
      if (playlist.ShowFront) {
        totalMessages += playlist.ShowFront.length;
      }
      if (playlist.ShowRear) {
        totalMessages += playlist.ShowRear.length;
      }
      if (playlist.ShowAll) {
        totalMessages += playlist.ShowAll.length;
      }
    });

    // command.TotalMessages = totalMessages;

    return totalMessages;
  };

  const isDynamic = () => {
    const ids = extractMessagesIds(command.Playlists);
    const filterMessagesByIds = messages.filter((message: any) => ids.includes(message.Id));
    const hasDynamicInputs: boolean = filterMessagesByIds.some((message: any) => message.DynamicInputs.length > 0);
    return hasDynamicInputs;
  }

  const redirection = (cmnd: any) => {
    if (cmnd.Playlists.length == 1) {
      localStorage.setItem('pTitle', 'Configuration');
      navigate(`/mobile/playlist?token=${token}&id=${cmnd.Playlists[0].Id}`)
    }
    else if (cmnd.Playlists.length > 1) {
      localStorage.setItem('pTitle', 'View Multilist');
      navigate(`/mobile/showcommand?token=${token}&id=14${cmnd.Id}`)
    }
  }

  return (
    <>
      {command &&
        <div className="config-item" key={index} onClick={(e) => { redirection(command) }}>
          {/* <img src="vacant-logo.png" alt="Vacant" /> */}
          <div className='config-item-container'>
            <div className='message-image' onClick={(e) => { e.stopPropagation() }}>
              <MessageRender message={firstMessage} />
            </div>
            <div className='config-title'>
              {String(command?.Label)}
              {/* <p className='mobile-content'>{getTotalMessages(command.Playlists)} {getTotalMessages(command.Playlists) > 1 ? ' messages' : ' message'}{command.Playlists.length > 1 ? ', Multilist' : ''}</p> */}
              {/* <p className='mobile-content'>{isDynamic() ? 'Dynamic' : ''}</p> */}
            </div>
          </div>
          <div className='config-properties-tablet'>
            <div className='properties'>
              {command.Lifetime > 0 &&
                <div className='property'>
                  <KTSVG
                    path='/media/svg/general/lifetime.svg'
                    className='svg-icon-command'
                  />
                  <div className='property-text'>
                    {command.Lifetime > 0 ? command.Lifetime / 1000 : '-'}s
                  </div>
                </div>
              }
              {command.Playlists.length > 1 &&
                <div className='property'>
                  <KTSVG
                    path='/media/svg/general/multilist.svg'
                    className='svg-icon-command'
                  />
                  <div className='property-text'>
                    MULTI
                  </div>
                </div>
              }
              {isDynamic() &&
                <div className='property'>
                  <KTSVG
                    path='/media/svg/general/dynamic.svg'
                    className='svg-icon-command'
                  />
                  <div className='property-text'>
                    DYN
                  </div>
                </div>
              }
              {getTotalMessages(command.Playlists) > 0 &&
                <div className='property'>
                  <KTSVG
                    path='/media/svg/general/messages.svg'
                    className='svg-icon-command'
                  />
                  <div className='property-text'>
                    {getTotalMessages(command.Playlists)}
                  </div>
                </div>
              }
            </div>
          </div>
          <div className='config-properties-mobile'>
            {getTotalMessages(command.Playlists) > 0 &&
              <div className='property'>
                <div className='property-text'>
                  {getTotalMessages(command.Playlists)}
                </div>
                <KTSVG
                  path='/media/svg/general/messages.svg'
                  className='svg-icon-command'
                />
              </div>
            }
            {isDynamic() &&
              <div className='property'>
                <div className='property-text'>
                  DYN
                </div>
                <KTSVG
                  path='/media/svg/general/dynamic.svg'
                  className='svg-icon-command'
                />
              </div>
            }
            {command.Playlists.length > 1 &&
              <div className='property'>
                <div className='property-text'>
                  MULTI
                </div>
                <KTSVG
                  path='/media/svg/general/multilist.svg'
                  className='svg-icon-command'
                />
              </div>
            }
            {command.Lifetime > 0 &&
              <div className='property'>
                <div className='property-text'>
                  {command.Lifetime > 0 ? command.Lifetime / 1000 : '-'}s
                </div>
                <KTSVG
                  path='/media/svg/general/lifetime.svg'
                  className='svg-icon-command'
                />
              </div>
            }
          </div>
        </div>
      }
    </>
  )
}

export default CommandRow
