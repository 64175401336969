import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { configurationCrudApi } from '../../../../redux/api'
import AddPlayListRow from './AddPlayListRow'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import IconWithHoverAndSelect from '../../../playlist/components/PlayListTable/IconWithHoverAndSelect'

interface InputProps {
  playLists: any,
  inputSelect?: any,
  handlePlaylistModal: () => void,
  addPlaylist: (data: any) => void,
  selectedList: any,
  setSelectedList: any,
  selector: any
}

const AddPlayListTable: React.FC<InputProps> = ({ playLists, selector, handlePlaylistModal, addPlaylist, selectedList, inputSelect, setSelectedList }) => {
  const [playlistIds, setPlaylistIds] = useState<any>([])

  const [filterPlaylists, setFilterPlayList]: any = useState(null);
  const [selectedIcon, setSelectedIcon] = useState<string | null>('Play');

  useEffect(() => {
    if (inputSelect == 'single') {
      getPlaylists();
    } else {
      getFilterPlaylists()
    }
  }, [selectedList, playLists])

  const configures = selectedList && selectedList.Playlists ? selectedList.Playlists : selectedList;

  const getFilterPlaylists = () => {

    const filterList = playLists?.filter((playList: any) => {
      return !configures?.some((item: any) => {
        return item[selector] == playList?.Id
      })
    })


    // const filterList = playLists;

    setFilterPlayList(filterList)
  }

  const getPlaylists = () => {
    setFilterPlayList(playLists)
  }

  const handelChangePlaylist = (playlist: any, e: any) => {
    if (inputSelect == 'single') {
      if (e.target.checked) {
        setPlaylistIds(playlist);
        setSelectedList(playlist);
      }
    } else {
      if (playlistIds?.length > 0 && !e.target.checked) {
        const filterPlaylists = playlistIds.filter((item: any) => item.Id !== playlist.Id);
        setPlaylistIds(filterPlaylists);
      } else {
        setPlaylistIds((oldArray: any) => [...oldArray, playlist])
      }
    }
  }

  const handlePlaylistAdd = () => {
    if (inputSelect == 'single') {
      addPlaylist(playlistIds);
    } else {
      playlistIds?.map((item: any, index: any) => {
        addPlaylist(item);
      });
    }

    handlePlaylistModal();
  }

  const previewPopOver = (
    <Popover id="popover-preview-details" className="max-width-300px text-center" style={{ fontSize: '0.925rem' }}>
      <Popover.Body>
        Choose how previews are shown.
        <br />
        <br />
        Start animation by hovering on image
        <br />
        Autoplay all animations
        <br />
        Show preview as images in order
      </Popover.Body>
    </Popover >
  );

  return (
    <>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-bordered fs-6 gy-5  no-footer table-striped'>
            <thead>
              <tr className='fw-bolder'>
                <th>Select</th>
                <th>Title</th>
                <th>Description</th>
                <th><div className='d-flex align-items-center'>
                  <React.Fragment>
                    <OverlayTrigger
                      trigger={['hover', 'focus', 'click']}
                      overlay={previewPopOver}
                      rootClose
                    >
                      <div>
                        Preview
                      </div>
                    </OverlayTrigger>
                    <div className='d-inline-flex align-items-center ml-8'>
                      <IconWithHoverAndSelect selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} />
                    </div>
                  </React.Fragment>
                </div></th>
              </tr>
            </thead>
            <tbody className='text-dark fw-bold'>
              {filterPlaylists && (
                <>
                  {filterPlaylists?.map((pl: any, i: any) => {
                    return (
                      <AddPlayListRow
                        handelChangePlaylist={handelChangePlaylist}
                        key={`pl${i}`}
                        playlist={pl}
                        inputSelect={inputSelect}
                        selectedIcon={selectedIcon}
                        selectedList={selectedList}
                      ></AddPlayListRow>
                    )
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='d-flex gap-9'>
        <button className='btn btn-primary btn-sm' onClick={handlePlaylistAdd}>Add Selected</button>
        <button className='btn btn-secondary btn-sm' onClick={handlePlaylistModal}>Cancel</button>
      </div>
    </>
  )
}

export default AddPlayListTable
