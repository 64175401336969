/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useEffect } from 'react'
import { Routes, Route, BrowserRouter, Navigate, Outlet, useLocation } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage } from '../modules/auth'
import { RootState } from '../../setup'
import { App } from '../App'
import Maintenance from '../modules/auth/components/Maintenance'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import * as auth from '../modules/auth/redux/AuthRedux'
import * as customer from '../redux/CustomersRedux'
import PlaylistView from '../components/playlist/PlaylistView'
import ConfigurationView from '../components/configuration/ConfigurationView'
import ShowCommandView from '../components/showCommand/ShowCommandView'
import PaymentSuccess from '../modules/subscriptions/PaymentSuccess'
import PaymentFail from '../modules/subscriptions/PaymentFail'
import { clearCustomerList } from '../../db/Customersdb'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom auth-bg position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/iToplight_logo.png')}
            className='h-100px img-fluid'
          />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        {/* <div className='bottom-logo'> */}
        <div>
          <img alt='PointGuard' className='img-fluid' src={toAbsoluteUrl('/media/logos/pointguard.png')} />
        </div>
        {/* </div> */}
        {/* <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div> */}
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AppRoutes: FC = () => {
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
  const queryPrams = window.location.search;
  const dispatch = useDispatch()

  useEffect(() => {
    const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE;

    if ((maintenanceMode == 'true' && localStorage.getItem('maintenanceMode') != 'true')) {
      localStorage.removeItem('k')
      localStorage.removeItem('p')
      localStorage.removeItem('customerId')
      localStorage.removeItem('group')
      // localStorage.removeItem('customerList')
      clearCustomerList();
      localStorage.removeItem('testDevice')
      localStorage.removeItem('maintenanceMode')

      dispatch(customer.actions.clearSelection());
      dispatch(auth.actions.logout())
    }
  }, []);

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>

          <Route path='/mobile/playlist' element={
            <PlaylistView />
          } />

          <Route path='/mobile/configuration' element={
            <ConfigurationView />
          } />

          <Route path='/mobile/showcommand' element={
            <ShowCommandView />
          } />

          <Route path='/subscriptions/paymentsuccess' element={
            <PaymentSuccess />
          } />

          <Route path='/subscriptions/paymentfail' element={
            <PaymentFail />
          } />

          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout/*' element={<Logout />} />
          <Route element={<AuthLayout />}>
            <Route path='maintenance/*' element={<Maintenance />} />
          </Route>
          {/* <Route path='set-password' element={<Navigate to={`/auth/set-password/${queryPrams}`}></Navigate>}></Route> */}
          <Route path='set-password' element={<Navigate to={`/auth/set-password${queryPrams}`} />} />

          {isAuthorized ? (
            <>
              <Route path='*' element={<PrivateRoutes />} />
              {/* <Route index element={<Navigate to='/dashboard' />} /> */}
              {/* <Route index element={<Navigate to='/devices/list' />} /> */}
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' replace />} />
              </>
          )}

        </Route>
      </Routes >
    </BrowserRouter >
  )
}

export { AppRoutes }
